import React, { useEffect, useState, useRef, useContext } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import { Prismic__FileLink } from "types/prismic";
import { PrismicImage } from "types/prismicImage";
import { PrismicRichText } from "types/prismicRichtext";

import { DashedAnimations } from "components/dashed-animation/DashedAnimations";
import { IDashedAnimationsItemProps } from "components/dashed-animation/DashedAnimationsItem";
import { RichText } from "components/contentful/rich-text/RichText";
import { Image } from "components/contentful/image/Image";

import { UIContext } from "context/ui";

import { scroll } from "constants/Scroll";
import { Breakpoints } from "constants/Breakpoints";

import s from "./InfoListItem.scss";
import { VideoPlayer } from "../video-player/VideoPlayer";
import { VideoItem } from "components/video-item/VideoItem";

interface IProps {
  item: IItemProps;
  index: number;
  isActive: boolean;
  currentItem: number;
  setCurrentItem: (number: number) => void;
  activeItem: number;
  activeItemHasImage: boolean;
  layoutOverlapped: boolean;
  imageAlignLeft: boolean;
  itemsTitleAsH2: boolean;
  borderRadius: "top" | "bottom" | "bottomLeft" | "bottomRight";
  isLastItem: boolean;
  extraIndent?: boolean;
  onClick: (action: any) => void;
  dashedAnimations?: Array<IDashedAnimationsItemProps>;
  hasParallax?: boolean;
  smallerImage?: boolean;
  transitionSimple?: boolean;
  largeVideos?: boolean;
  expandedOnMobile?: boolean;
  lazyLoadVideos?: boolean;
  disableImageLazyLoad?: boolean;
  evenColumns?: boolean;
  borderBottomLeftRadius?: boolean;
}

interface IItemProps {
  title: string;
  richtext: PrismicRichText;
  img?: PrismicImage;
  video?: Prismic__FileLink;
  video_fallback?: Prismic__FileLink;
}

export const InfoListItem = ({
  item,
  index,
  isActive,
  currentItem,
  setCurrentItem,
  activeItem,
  activeItemHasImage,
  layoutOverlapped,
  imageAlignLeft,
  itemsTitleAsH2,
  borderRadius,
  isLastItem = false,
  extraIndent = false,
  onClick,
  dashedAnimations,
  hasParallax = false,
  smallerImage = false,
  transitionSimple = false,
  largeVideos = false,
  smallVideos = false,
  expandedOnMobile = false,
  lazyLoadVideos = false,
  disableImageLazyLoad = false,
  evenColumns = false,
  borderBottomLeftRadius = false
}: any) => {
  const { isMobile, isDesktop } = useContext(UIContext);
  const [isActiveMobile, setIsActiveMobile] = useState(
    expandedOnMobile ? true : isActive
  );
  // const [currentItem, setCurrentItem] = useState(-1);
  // const [activeImage, setActiveImage] = useState(0);
  const content = useRef<HTMLDivElement>(null);
  const contentInner = useRef<HTMLDivElement>(null);
  const heading = useRef<HTMLDivElement>(null);
  const imageContainer = useRef<HTMLElement>(null);
  const imageWrap = useRef<HTMLElement>(null);
  const image = useRef<HTMLElement>(null);
  const video = useRef<HTMLVideoElement>(null);
  const videoSourceRef = useRef<HTMLSourceElement>(null);

  const itemVideo = item.video;
  const itemVideoUrl = item.videoUrl;
  const itemImage = item.image;
  const itemText = item.text;
  const borderRadiusAll = true;
  
  let matchMediaMobile: boolean;
  if (typeof window !== "undefined" && typeof document !== "undefined") {
    matchMediaMobile = window.matchMedia(
      `(max-width: ${Breakpoints.maxTablet}px)`
    ).matches;
  }

  // update active item
  useEffect(() => {
    if (matchMediaMobile) {
      // Animate content
      const newHeigth: number = isActiveMobile
        ? contentInner.current!.offsetHeight
        : 0;
      gsap.to(content.current!, {
        height: newHeigth,
        duration: 0.45,
        ease: "connect-easing3",
        onComplete: () => {
          window.smoothScroll && window.smoothScroll.onResize();
        }
      });
    } else {
      // Animate content
      const newHeigth: number = isActive
        ? contentInner.current!.offsetHeight
        : 0;
      //Power3.easeInOut
      gsap.to(content.current!, {
        height: newHeigth,
        duration: 0.7,
        ease: "connect-easing3",
        onComplete: () => {
          window.smoothScroll && window.smoothScroll.onResize();
        }
      });

      // Animate Image
      if (image.current && activeItemHasImage) {
        if (currentItem === activeItem) return;

        const yPercentFrom = activeItem > currentItem ? -100 : 100;
        const yPercentTo = activeItem > currentItem ? -100.1 : 100.1;

        if (transitionSimple) {
          // Transiton: just mask
          gsap.fromTo(
            image.current!,
            { opacity: isActive ? 0 : 1 },
            {
              duration: 0.8,
              ease: "connect-easing3",
              opacity: isActive ? 1 : 0
            }
          );
        } else {
          // Transiton: mask + zoom + slide
          gsap.fromTo(
            imageWrap.current!,
            { yPercent: isActive ? -yPercentFrom : 0 },
            {
              duration: 0.8,
              ease: "connect-easing3",
              yPercent: isActive ? 0 : yPercentTo
            }
          );

          if (!isActive) {
            setTimeout(() => gsap.set(image.current!, { scale: 1 }), 800);
          }

          gsap.fromTo(
            image.current!,
            { yPercent: isActive ? yPercentFrom : 0, y: 0 },
            {
              duration: 0.8,
              ease: "connect-easing3",
              yPercent: isActive ? 0 : -yPercentTo,
              y: isActive ? 0 : yPercentTo * 1.1,
              scale: 1.05
            }
          );
        }

        setCurrentItem(activeItem);
      }
    }

    if (
      (video.current && isDesktop && isActive) ||
      (video.current && isMobile && isActiveMobile)
    ) {
      // only play the video of the active item
      video.current.pause();
      video.current.currentTime = 0;
      setTimeout(() => {
        video.current.play();
      }, 750);
    }

    if (
      (video.current && isDesktop && !isActive) ||
      (video.current && isMobile && !isActiveMobile)
    ) {
      // pause the inactive videos
      setTimeout(() => {
        video.current.pause();
        video.current.currentTime = 0;
      }, 1200);
    }
  }, [isActive, activeItemHasImage, isActiveMobile]);

  useEffect(() => {
    // Set Initial image height to auto
    isActive && index == 0 && gsap.to(content.current!, { height: "auto" });
    isActiveMobile && gsap.to(content.current!, { height: "auto" });

    if (video.current && (isActive || isActiveMobile || layoutOverlapped)) {
      // Pause initial video
      setTimeout(() => {
        video.current.pause();
        video.current.currentTime = 0;
      }, 1600);

      // Trigger initial video play when it enters on screen
      setTimeout(() => {
        gsap.to(video.current, {
          opacity: 1,
          onComplete: () => {
            if (video.current) {
              video.current.play();
            }
          },
          scrollTrigger: {
            scroller: scroll.container,
            trigger: video.current,
            once: true
          }
        });
      }, 1500);

      // fix
      if (video.current) {
        setTimeout(() => {
          video.current.load();
        }, 500);
      }
    }

    // Lazy load videos
    if (lazyLoadVideos && videoSourceRef.current) {
      setTimeout(() => {
        if (video.current) {
          ScrollTrigger.create({
            scroller: scroll.container,
            trigger: image.current,
            start: "-400% 100%",
            onEnter: () => {
              if (videoSourceRef.current) {
                videoSourceRef.current.src =
                  videoSourceRef.current?.dataset.src;
                video.current.load();
              }
            }
          });
        }
      }, 1500);
    }
  }, []);

  useEffect(() => {
    if (isDesktop && hasParallax) {
      if (imageContainer.current && imageWrap.current) {
        gsap.set(imageWrap.current, { scale: 1.3 });
        gsap.fromTo(
          imageWrap.current,
          {
            y: -100
          },
          {
            y: 100,
            scrollTrigger: {
              scroller: scroll.container,
              trigger: imageContainer.current,
              scrub: true
            }
          }
        );
      }
    }
  }, [isDesktop]);

  const onClickHandler = e => {
    if (isMobile) {
      setIsActiveMobile(currentActiveMobile => !currentActiveMobile);
    } else {
      onClick(e);
    }
  };

  const indexAria = Math.floor(Math.random() * 1000);

  // Return
  return (
    <li
      className={s("infoListItem", {
        layoutOverlapped,
        imageAlignLeft,
        extraIndent,
        smallerImage,
        evenColumns
      })}
    >
      <div className={s.infoList__textWrapper}>
        {item.title && (
          <div
            className={s("infoListItem__heading", {
              active: isMobile ? isActiveMobile : isActive
            })}
            ref={heading}
            data-index={index}
            data-has-image={itemImage || item.video ? true : false}
            onClick={onClickHandler}
          >
            <div className={s.infoListItem__heading__symbol}></div>
            <button
              className={s("infoListItem__heading__title", { itemsTitleAsH2 })}
              type="button"
              aria-controls={`content-${index}${indexAria}`}
              aria-expanded={isActive || isActiveMobile ? true : false}
            >
              {item.title && item.title}
            </button>
          </div>
        )}
        <div
          className={s("infoListItem__content", { isLastItem })}
          ref={content}
          id={`content-${index}${indexAria}`}
          aria-hidden={isActive || isMobile ? undefined : true}
        >
          <div ref={contentInner}>
            {itemText && (
              <div className={s.infoListItem__content__textContainer}>
                <RichText richText={itemText} />
              </div>
            )}
            {(itemImage || itemVideo) && (
              <div
                ref={imageContainer}
                className={s(
                  "infoListItem__img",
                  borderRadiusAll,
                  { largeVideos, smallVideos }
                )}
              >
                <div className={s.infoListItem__img__figure} ref={imageWrap}>
                  <div ref={image}>
                    {dashedAnimations && (
                      <DashedAnimations
                        items={dashedAnimations}
                        isActive={isActive}
                      />
                    )}

                    {itemImage && !itemVideo && (
                      <Image
                        image={itemImage}
                        srcSetMobile={isMobile}
                        loading={disableImageLazyLoad ? "eager" : "lazy"}
                        objectFit="cover"
                      />
                    )}

                    {(itemVideo || itemVideoUrl) && (
                      <div className={s.smallVideos}>
                        <div>
                          <VideoItem
                            item={{
                              video: item.video,
                              videoUrl: item?.videoUrl,
                              showPlayButton: false,
                              description: item.video?.description,
                              autoplay: true,
                              poster: itemImage?.file?.url
                            }}
                            autoplay={true}
                            loop={true}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};
